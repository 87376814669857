export default {
  pageName: 'page_live_chat',
  newPosKeys: [
    'MultiPushList',
    'UnrecongizedCard',
    'FuzzyMatchCard',
    'ConfirmationNoCard',
    'CsOrderlistCard',
    'CsOrderdetailCard',
    'CSchat',
    'ExchangeFunction',
    'robotView',
    'BotStrategy',
    'inviteReview',
    'switchOrder',
    'returnUrge'
  ],
  posKeys: [
    'Orderdetail',
    'Multiround',
    'Slq',
    'IsCanRefund',
    'giftOrderDetail',
    'IsInfoFront',
    'OrderlistQueryRelatedAccounts',
  ],
}
